/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $half-spacing-unit;
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure.graphic {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  object {
    width: 100%;
  }
}

figcaption {
  clear: left;
  margin:.75em 0;
  text-align: center;
  font-weight: bold;
  line-height: 1.5em;
  font-size: $small-font-size;
}




/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

@mixin blank-list {
  list-style-type: none;
}

@mixin inline-list {
  list-style-type: none; margin: 0; padding: 0;
  li { @include blank-list; display: inline; margin: 0; padding: 0; }
}

@mixin comma-list {
  @include inline-list;
  li:after { content: ", "; }
  li:last-child:after { content: ""; }
}

.inline-list { @include inline-list; }
.comma-list { @include comma-list; }

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
  color: $link-color;
  text-decoration: none;

  &:not(.social-profile) {
    text-decoration: underline;
  }

  &.social-profile {
    .icon {
      text-decoration: none;
    }

    .username {
      text-decoration: underline;
    }
  }

  &:visited {
    color: darken($link-color, 15%);
  }

  &:hover {
    color: $text-color;
  }
}

/**
 * Blockquotes
 */
blockquote {
  color: $grey-color-dark;
  border-left: 5px solid $grey-color;
  padding-left: $half-spacing-unit;
  font-size: 18px;
  letter-spacing: -1px;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  font-size: 15px;
/*   border: 1px solid $grey-color-light; */
  border-radius: 3px;
/*   background-color: #eef; */
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - #{$double-spacing-unit});
  max-width:         calc(#{$content-width} - #{$double-spacing-unit});
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - #{$spacing-unit});
    max-width:         calc(#{$content-width} - #{$spacing-unit});
    padding-right: $half-spacing-unit;
    padding-left: $half-spacing-unit;
  }
}

table.booktab {
  margin: 3ex auto;
  border-top: $grey-color medium solid;
  border-bottom: $grey-color medium solid;
  padding-top: 0.5ex;
  padding-bottom: 0.5ex;

  thead tr th {
    padding-bottom: 1ex;
    padding-top: 0.5ex;
    border-bottom: $grey-color thin solid;
  }

  tbody > tr:first-child td { padding-top: 0.5ex; }
  tbody > tr:last-child  td { padding-bottom: 0.5ex; }

  thead tr *,
  thead td *,
  tbody tr *,
  tbody td * {
    padding-right: 0.3em;
    padding-left: 0.3em;
    vertical-align: top;
  }
}


/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/**
 * Tags
 */

/* tag_box */
.tag_box {
  list-style: none;
  margin: 0;
  margin-bottom: 1em;
  overflow: hidden;

  li {
    line-height: 28px;
  }

  li i {
    opacity: 0.9;
  }

  .inline li {
    float: left;
  }

  a {
    padding: 3px 6px;
    margin: 2px;
    background: $grey-color-light;
    color: $grey-color-dark;
    border-radius: 3px;
    text-decoration: none;
    border: 1px dashed $grey-color;
  }

  a span {
    vertical-align: super;
    font-size: 0.8em;
  }

  a:hover {
    background-color: darken($grey-color-light, 10%);
  }

  a.active {
    background: #57A957;
    border: 1px solid #4c964d;
    color: #FFF;
  }
}



/**
 * Icons
 */
.social-media-list {
  .icon {
    width: 16px;
    height:16px;

    > svg {
      width: inherit;
      height:inherit;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .username {
    margin-left: .5em;

  }

  .username:hover {
    text-decoration: underline;
  }
}

hr { margin-bottom: $half-spacing-unit; }

.remark {
  border: 1px dashed #404040;
  color: $grey-color-dark;
  padding: $half-spacing-unit;
  padding-bottom: 0;
  width: 85%;
  margin: 0 auto $half-spacing-unit;
}

.center {
  text-align: center;
}

#validated {
  width: 100%;
  padding-bottom: 0.5em;
  text-align: center;

  ul {
    margin: 0 auto $half-spacing-unit;
    vertical-align: middle;
    padding: 0;
  }
  li {
    padding-right: $half-spacing-unit;
    display: inline-block;
  }
  img {
    display: inline;
    height: 32px;
    border: 0;
  }
}


/**
 * Japanese accents
 */
/*From http://accent.u-biq.org/accent.css */

.japanese-accent {
  line-height: 150%;

  .R {
    padding-top: 1px;
    border-bottom: #ff6633 1px solid;
    border-right: #ff6633 1px solid;
  }
  .F {
    padding-bottom: 1px;
    border-top: #ff6633 1px solid;
    border-right: #ff6633 1px solid;
  }
  .L {
    border-bottom: #ff6633 1px solid;
  }
  .H {
    border-top: #ff6633 1px solid;
  }
}
