/**
 * Site header
 */
.site-header {
  min-height: 56px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

#site-title {
  height: 4em;
  background: #000000;
  text-align: center;

  a {
    text-decoration: none;
    color: $background-color;
    font-weight: bold;
    font-size: 2em;
    line-height: 2em;
  }
}

#site-nav {
/*   float: right; */
/*   line-height: 56px; */

  width: 100%;
  height: 3em;
  text-align: center;
  position: relative;

  /* Inline link bars for navigational header
   Based on examples from http://www.alistapart.com/articles/taminglists/
   and http://www.kriesi.at/archives/create-a-multilevel-dropdown-menu-with-css-and-improve-it-via-jquery */

  .nav-bar {
    background: #000000;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      font-weight: bold;
      line-height: 2.5em;
      padding: 0 1em;
    }

    a:link              { text-decoration: none; color: $background-color }
    a:visited           { text-decoration: none; color: $background-color }
    a:hover             { color: $brand-color }
    li.active           { background: $background-color }
    li.active a:link    { text-decoration: none; color: #000000 }
    li.active a:visited { text-decoration: none; color: #000000 }
    li.active a:hover   { color: $brand-color }
  }


}



/**
 * Site footer
 */
.site-footer {
  padding: 0 0 $spacing-unit 0;

  .wrapper {

    .fleuron {
      min-height: 50px;
      background: $background-color url(images/fleuron.svg) no-repeat scroll center;
      border: none;
      display: block;
    }
    .fleuron hr {
      display: none;
    }

  }
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $half-spacing-unit;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color-dark;
  margin-left: -$half-spacing-unit;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $half-spacing-unit;
  padding-left: $half-spacing-unit;
}

.footer-col-1 {
  width: -webkit-calc(35% - #{$half-spacing-unit});
  width:         calc(35% - #{$half-spacing-unit});
}

.footer-col-2 {
  width: -webkit-calc(20% - #{$half-spacing-unit});
  width:         calc(20% - #{$half-spacing-unit});
}

.footer-col-3 {
  width: -webkit-calc(45% - #{$half-spacing-unit});
  width:         calc(45% - #{$half-spacing-unit});
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - #{$half-spacing-unit});
    width:         calc(50% - #{$half-spacing-unit});
  }

  .footer-col-3 {
    width: -webkit-calc(100% - #{$half-spacing-unit});
    width:         calc(100% - #{$half-spacing-unit});
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - #{$half-spacing-unit});
    width:         calc(100% - #{$half-spacing-unit});
  }
}


.content {
  padding: $spacing-unit 0;
}

/**
 * Page content
 */
.page-title {
  text-align: left;
  font-size: 2em;
  font-weight: bold;
}

.page-content {
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $half-spacing-unit;
  }
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;

  a {
    color: $link-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.left-right {
  display: block;
  width: auto;
  overflow: hidden;

  .left, .right {
    display: block;
    width: 50%;
    float: left;
    margin-bottom: 0;
  }

  .right {
    text-align: right;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color-dark;
}

.post-link {
  display: block;
  font-size: 24px;
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }

}

.post-navigation {
  font-size: 14px;
  min-height: 2em;
}

.footnotes {
  margin-top: $half-spacing-unit;
  padding-top: $half-spacing-unit;
  border-top: solid gray 1px;
}

/**
 * Resume
 */

.resume {
  figure {
    width: 100%;
    text-align: center;

    img {
      width: 50%;
    }
  }

  .contact {
    dl {
      dt {
        float: left;
        clear: left;
        text-align: left;
      }

      dd {
        margin: 0 0 0 8em;
        padding-bottom: 0.5em;
      }
    }
  }

  .references {
    ul { @include blank-list }
  }

  dl {
    dd {
      margin: 0 0 0 2em;
      padding-bottom: 0.5em;
    }
  }

  .keywords {
    p {
      display: inline;
      clear: left;
    }
    p:after { content: ":"; }
    ul {
      @include comma-list;
      display: inline;
    }
  }
}


#sticker {
  position: fixed;
  bottom: -7em;
  right: -5em;
  background: #40b3dd;
  height: 14em;
  width: 14em;
  border-radius: 50%;
  display: table;
  border: 2px solid white;

  span {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-left: 10%;
    padding-right: 45%;
    padding-bottom: 40%;
    width: 20%;

    a {
      font-weight: bold;
      text-decoration: none;
      color: white;
    }
  }
}

.equation {
  font-family: serif;
  font-style: italic;
  text-align: center;
  padding: 2em;
}

div.date {
  font-size: small;
  margin-top: 2em;
  margin-bottom: 2em;
  font-style: italic;
  text-align: right;
}
