/**
 * Syntax highlighting styles
 * Based on Rouge's Thankful Eyes theme
 */
.highlight {
  color: #faf6e4;
  background: #122b3b;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: #122b3b;
  }

  .c     { color: #6c8b9f; font-style: italic } // Comment
  .cd    { color: #6c8b9f; font-style: italic } // Comment
  .cm    { color: #6c8b9f; font-style: italic } // Comment.Multiline
  .c1    { color: #6c8b9f; font-style: italic } // Comment.Single
  .cs    { color: #6c8b9f; font-style: italic } // Comment.Special
  .cp    { color: #b2fd6d; font-weight: bold; font-style: italic } // Comment.Preproc
  .err   { color: #fefeec; background-color: #cc0000 } // Error
  .k     { color: #f6dd62; font-weight: bold } // Keyword
  .o     { color: #4df4ff; font-weight: bold } // Operator
  .gd    { color: #cc0000 } // Generic.Deleted
  .gl    { color: #dee5e7; background-color: #4e5d62 } // Generic.Deleted
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: #fefeec; background-color: #cc0000 } // Generic.Error
  .gh    { color: #ffb000; font-weight: bold } // Generic.Heading
  .gi    { color: #b2fd6d } // Generic.Inserted
  .p     { color: #4df4ff } //
  .pi    { color: #4df4ff } //
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: #ffb000; font-weight: bold } // Generic.Subheading
  .gt    { color: #dee5e7; background-color: #4e5d62 } // Generic.Traceback
  .kc    { color: #f696db; font-weight: bold } // Keyword.Constant
  .kn    { color: #f696db; font-weight: bold } // Keyword.Constant
  .kd    { color: #f6dd62; font-weight: bold } // Keyword.Declaration
  .kv    { color: #f6dd62; font-weight: bold } // Keyword.Declaration
  .kp    { color: #ffb000; font-weight: bold } // Keyword.Pseudo
  .kr    { color: #ffb000; font-weight: bold } // Keyword.Reserved
  .nl    { color: #ffb000; font-weight: bold } //
  .kt    { color: #b2fd6d; font-weight: bold } // Keyword.Type
  .m     { color: #f696db; font-weight: bold } // Literal.Number
  .na    { color: #a8e1fe } // Name.Attribute
  .py    { color: #a8e1fe } //
  .nb    { font-weight: bold } // Name.Builtin
  .nc    { color: #b2fd6d; font-weight: bold } // Name.Class
  .nn    { color: #b2fd6d; font-weight: bold } // Name.Namespace
  .no    { color: #b2fd6d; font-weight: bold } // Name.Constant
  .nd    { color: #b2fd6d; font-weight: bold } //
  .ni    { color: #999999; font-weight: bold } // Name.Entity
  .ne    { color: #b2fd6d; font-weight: bold } // Name.Exception
  .nf    { color: #a8e1fe } // Name.Function
  .nt    { color: #ffb000; font-weight: bold } // Name.Tag
  .ow    { color: #4df4ff; font-weight: bold } // Operator.Word
  .w     { color: #bbb } // Text.Whitespace
  .mf    { color: #f696db; font-weight: bold } // Literal.Number.Float
  .mh    { color: #f696db; font-weight: bold } // Literal.Number.Hex
  .mi    { color: #f696db; font-weight: bold } // Literal.Number.Integer
  .mo    { color: #f696db; font-weight: bold } // Literal.Number.Oct
  .mb    { color: #f696db; font-weight: bold } //
  .mx    { color: #f696db; font-weight: bold } //
  .ld    { color: #f696db; font-weight: bold } //
  .s     { color: #fff0a6; font-weight: bold } // Literal.String
  .sc    { color: #4df4ff; font-weight: bold } // Literal.String.Char
  .sb    { color: #fff0a6; font-weight: bold } // Literal.String.Backtick
  .sd    { color: #fff0a6; font-weight: bold } // Literal.String.Doc
  .s2    { color: #fff0a6; font-weight: bold } // Literal.String.Double
  .se    { color: #4df4ff; font-weight: bold } // Literal.String.Escape
  .sh    { color: #fff0a6; font-weight: bold } // Literal.String.Heredoc
  .si    { color: #4df4ff; font-weight: bold } // Literal.String.Interpol
  .sx    { color: #fff0a6; font-weight: bold } // Literal.String.Other
  .sr    { color: #fff0a6; font-weight: bold } // Literal.String.Regex
  .s1    { color: #fff0a6; font-weight: bold } // Literal.String.Single
  .ss    { color: #f696db; font-weight: bold } // Literal.String.Symbol
  .bp    { color: #b2fd6d; font-weight: bold } // Name.Builtin.Pseudo
  .nv    { color: #a8e1fe; font-weight: bold } // Name.Variable
  .vc    { color: #a8e1fe; font-weight: bold } // Name.Variable.Class
  .vg    { color: #a8e1fe; font-weight: bold } // Name.Variable.Global
  .vi    { color: #a8e1fe; font-weight: bold } // Name.Variable.Instance
  .il    { color: #f696db; font-weight: bold } // Literal.Number.Integer.Long

  /*   .gd .x { color: #cc0000; background-color: #faa } // Generic.Deleted.Specific */
  /*   .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific */
  /*   .go    { color: #888 } // Generic.Output */
  /*   .gp    { color: #555 } // Generic.Prompt */
}
