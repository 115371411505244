.highlight {
  color: #bbbbbb;
  background-color: #000;

  table td { padding: 5px; }
  table pre { margin: 0; }

  .w {
    color: #bbbbbb;
    background-color: #000;
  }

  .c, .cd, .cm, .c1 {
    color: #888;
  }
  .cp {
    color: #579;
  }
  .cs {
    color: #cc0000;
    font-weight: bold;
  }
  .k, .kc, .kd, .kn, .kr, .kv {
    color: #080;
    font-weight: bold;
  }
  .kp {
    color: #038;
  }
  .kt {
    color: #339;
  }
  .o {
    color: #333;
  }
  .ow {
    color: #000;
    font-weight: bold;
  }
  .nb, .bp {
    color: #007020;
  }
  .nf {
    color: #06B;
    font-weight: bold;
  }
  .nc {
    color: #B06;
    font-weight: bold;
  }
  .nn {
    color: #0e84b5;
    font-weight: bold;
  }
  .ne {
    color: #F00;
    font-weight: bold;
  }
  .nv {
    color: #963;
  }
  .vi {
    color: #33B;
  }
  .vc {
    color: #369;
  }
  .vg {
    color: #d70;
    font-weight: bold;
  }
  .no {
    color: #036;
    font-weight: bold;
  }
  .nl {
    color: #970;
    font-weight: bold;
  }
  .ni {
    color: #800;
    font-weight: bold;
  }
  .na {
    color: #00C;
  }
  .nt {
    color: #070;
  }
  .nd {
    color: #555;
    font-weight: bold;
  }
  .s, .sb, .s2, .sh, .s1 {
    background-color: #fff0f0;
  }
  .sc {
    color: #04D;
  }
  .sd {
    color: #D42;
  }
  .si {
    background-color: #eee;
  }
  .se {
    color: #666;
    font-weight: bold;
  }
  .sr {
    color: #000;
    background-color: #fff0ff;
  }
  .ss {
    color: #A60;
  }
  .sx {
    color: #D20;
  }
  .m, .mb, .mx {
    color: #60E;
    font-weight: bold;
  }
  .mi, .il {
    color: #00D;
    font-weight: bold;
  }
  .mf {
    color: #60E;
    font-weight: bold;
  }
  .mh {
    color: #058;
    font-weight: bold;
  }
  .mo {
    color: #40E;
    font-weight: bold;
  }
  .gh {
    color: #000080;
    font-weight: bold;
  }
  .gu {
    color: #800080;
    font-weight: bold;
  }
  .gd {
    color: #A00000;
  }
  .gi {
    color: #00A000;
  }
  .gr {
    color: #FF0000;
  }
  .ge {
    font-style: italic;
  }
  .gs {
    font-weight: bold;
  }
  .gp {
    color: #c65d09;
    font-weight: bold;
  }
  .go {
    color: #888;
  }
  .gt {
    color: #04D;
  }
  .err {
    color: #F00;
    background-color: #FAA;
  }
}
