// Define defaults for each variable.

$base-font-family: Ubuntu, "Free Sans", Verdana, sans-serif !default;
$code-font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:        30px !default;
$half-spacing-unit:   15px !default;
$double-spacing-unit: 60px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #40b3dd !default;
$link-color:       darken($brand-color, 15%) !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    700px !default;

$on-palm:          600px !default;
$on-laptop:        700px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $half-spacing-unit;
//     padding-left: $half-spacing-unit;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "pinguino-rodriguez/base",
  "pinguino-rodriguez/layout"
;

// Import highlighters
@import "pinguino-rodriguez/syntax/solarized_light";

.solarized-light {
  @import "pinguino-rodriguez/syntax/solarized_light";
}

.thankful-eyes {
  @import "pinguino-rodriguez/syntax/thankful_eyes";
}

.colorful {
  @import "pinguino-rodriguez/syntax/colorful";
}
